// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

/** Custom fonts **/
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/ProximaNova_Regular.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/ProximaNova_Light.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/ProximaNova_Semibold.otf');
  font-display: swap;
}

// Plus imports for other components in your app.

// Theme Config
:root {
  --font-color-header: #283041;
  --font-color-dark: #303743;
  --font-color-gray: #9ea9b7;
  --font-color-light-gray: #f9f9f9;
  --white-color: #ffffff;
  --surface-color: #f4f7fa;
  --primary-color: #07335e;
  --primary-lighter-color: #b5c2cf;
  --primary-darker-color: #042042;
  --text-primary-color: var(--white-color);
  --text-primary-lighter-color: var(--font-color-dark);
  --text-primary-darker-color: var(--white-color);
  --accent-color: #459df9;
  --accent-lighter-color: #c7e2fd;
  --accent-darker-color: #2e81f6;
  --text-accent-color: var(--white-color);
  --text-accent-lighter-color: var(--font-color-dark);
  --text-accent-darker-color: var(--white-color);
  --warn-color: #fc77a0;
  --warn-lighter-color: #fed6e3;
  --warn-darker-color: #f34355;
  --text-warn-color: var(--warn-darker-color);
  --text-warn-lighter-color: var(--warn-darker-color);
  --text-warn-darker-color: var(--white-color);

  /** Snackbar **/
  --success-bg-color: #D7F5E7;
  --success-text-color: #38CB89;
  --error-bg-color: #FDD9DD;
  --error-text-color: #F34355;
  --warning-bg-color: #FFEECC;
  --warning-text-color: #FFAB00;
  --info-bg-color: #d3ecf2;
  --info-text-color: #219EBC;
  --line-grey-color:#E2E8F0;
  --text-grey-color: #6E6E6E;

  /** transparent **/
  --black-alpha-35: #00000059;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Fonts
$hirit-typography-config: mat.define-typography-config(
  $font-family: 'Proxima Nova',
  $headline-1: mat.define-typography-level(2em, 2.5em, 500, 'Maven Pro', 0em) /* h1 */,
  $headline-2: mat.define-typography-level(1.5em, 2em, 400, 'Maven Pro', 0.0047em) /* h2 */,
  $headline-3: mat.define-typography-level(1.125em, 1.5em, 500, 'Maven Pro', 0.0139em),
  $headline-4: mat.define-typography-level(1.25em, 1.75em, 500, 'Maven Pro', 0em),
  $headline-5: mat.define-typography-level(1.5em, 2em, 500, 'Maven Pro', -0.0208em),
  $headline-6: mat.define-typography-level(2em, 2.5em, 500, 'Maven Pro', -0.0469em),
  $subtitle-1: mat.define-typography-level(1em, 1.5em, 500, 'Maven Pro', 0.0094em) /* h4 */,
  $subtitle-2: mat.define-typography-level(1.125em, 1.5em, 500, 'Maven Pro', 0.0056em) /* h3 */,
  $body-1: mat.define-typography-level(1em, 1.5em, 400, 'Proxima Nova', 0.0156em),
  $body-2: mat.define-typography-level(1em, 1.5em, 500, 'Proxima Nova', 0.0156em),
  $caption: mat.define-typography-level(0.75em, 1em, 400, 'Proxima Nova', 0.0333em),
  $button: mat.define-typography-level(1em, 1.5em, 500, 'Proxima Nova', 0.0781em),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($hirit-typography-config);`
@include mat.all-component-typographies($hirit-typography-config);
@include mat.core();

$dark-primary-text: var(--font-color-dark);
$dark-secondary-text: var(--font-color-gray);
$light-primary-text: var(--white-color);
$light-secondary-text: var(--font-color-gray);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
/* For use in src/lib/core/theming/_palette.scss */
$primary-palette: (
  50: #e1e7ec,
  100: #b5c2cf,
  200: #8399af,
  300: #51708e,
  400: #2c5276,
  500: #07335e,
  600: #062e56,
  700: #05274c,
  800: #042042,
  900: #021431,
  A100: #6995ff,
  A200: #3671ff,
  A400: #034dff,
  A700: #0044e9,
  contrast: (
    50: #07335e,
    100: #07335e,
    200: #07335e,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: #07335e,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
$hirit-bo-primary: mat.define-palette($primary-palette);

$accent-palette: (
  50: #e9f3fe,
  100: #c7e2fd,
  200: #a2cefc,
  300: #7dbafb,
  400: #61acfa,
  500: #459df9,
  600: #3e95f8,
  700: #368bf7,
  800: #2e81f6,
  900: #1f6ff5,
  A100: $light-primary-text,
  A200: #f7faff,
  A400: #c4d9ff,
  A700: #abc8ff,
  contrast: (
    50: #459df9,
    100: #459df9,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: #459df9,
    A200: #459df9,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);
$hirit-bo-accent: mat.define-palette($accent-palette);

$warn-palette: (
  50: #ffeff4,
  100: #fed6e3,
  200: #febbd0,
  300: #fda0bd,
  400: #fc8bae,
  500: #fc77a0,
  600: #fc6f98,
  700: #fb648e,
  800: #fb5a84,
  900: #fa4773,
  A100: $light-primary-text,
  A200: $light-primary-text,
  A400: #ffe9ee,
  A700: #ffcfda,
  contrast: (
    50: #f34355,
    100: #f34355,
    200: #f34355,
    300: #f34355,
    400: #f34355,
    500: #f34355,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: #f34355,
    A200: #f34355,
    A400: #f34355,
    A700: #f34355,
  ),
);
$hirit-bo-warn: mat.define-palette($warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hirit-bo-theme: mat.define-light-theme(
  (
    color: (
      primary: $hirit-bo-primary,
      accent: $hirit-bo-accent,
      warn: $hirit-bo-warn,
    ),
    typography: $hirit-typography-config,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hirit-bo-theme);

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-button-base {
  &.mat-button,
  &.mat-raised-button,
  &.mat-stroked-button,
  &.mat-flat-button {
    padding: 0 1.15em;
    height: 3rem;
    line-height: 1.5rem;
    font-weight: 600;

    &.rounded {
      border-radius: 64px;
    }

    &.small {
      height: 2.5rem;
      line-height: 1.5rem;
    }

    &.mat-warn {
      background-color: #F3435526;
    }
  }

  .mat-button-ripple.mat-ripple,
  .mat-button-focus-overlay {
    margin-bottom: auto;
  }
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: currentColor;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Proxima Nova';
  }
}

/** MatFormField **/
label,
mat-label,
span {
  font-weight: normal;
  font-size: 1em;
  line-height: 1.5em;
  margin-bottom: .25em;
}

.mat-form-field {
  width: 100%;
  margin: 0 0 .75em;
  color: var(--font-color-dark);

  .mat-form-field-wrapper {
   padding-bottom: 0;
  }

  &.mat-form-field-disabled input {
    text-overflow: ellipsis;
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-outline {
      top: 0;
    }

    .mat-form-field-infix {
      padding: .75em 0;
      border-top: 0;
      margin: 0 6px;
    }

    .mat-form-field-outline-start {
      border-radius: 22px 0 0 22px;
      min-width: 24px;
    }

    .mat-form-field-outline-end {
      border-radius: 0 22px 22px 0;
    }

    .mat-form-field-flex .mat-form-field-outline-start,
    .mat-form-field-flex .mat-form-field-outline-end,
    .mat-form-field-flex .mat-form-field-outline-gap {
      border-width: 0;
      background-color: var(--surface-color);
    }

    ::placeholder {
      text-overflow: ellipsis;
      color: var(--font-color-dark);
    }
  }
}

/** SnackBar **/
.mat-snack-bar-container.snack-bar {
  display: flex;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(196, 196, 196, 0.67);
  border-radius: 10px;

  &.snack-bar-success {
    border-color: var(--success-text-color);
    background-color: var(--success-bg-color);
    color: var(--success-text-color);

    span.mat-simple-snack-bar-content::before {
      content: "\e86c";
    }

    .mat-button {
      color: var(--success-text-color);
    }
  }

  &.snack-bar-error {
    border-color: var(--error-text-color);
    background-color: var(--error-bg-color);
    color: var(--error-text-color);

    span.mat-simple-snack-bar-content::before {
      content: "\e5c9";
    }

    .mat-button {
      color: var(--error-text-color);
    }
  }

  &.snack-bar-info {
    border-color: var(--info-text-color);
    background-color: var(--info-bg-color);
    color: var(--info-text-color);

    span.mat-simple-snack-bar-content::before {
      content: "\e001";
    }

    .mat-button {
      color: var(--info-text-color);
    }
  }

  &.snack-bar-warning {
    border-color: var(--warning-text-color);
    background-color: var(--warning-bg-color);
    color: var(--warning-text-color);

    span.mat-simple-snack-bar-content::before {
      content: "\e88e";
    }

    .mat-button {
      color: var(--warning-text-color);
    }
  }

  span.mat-simple-snack-bar-content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    &::before {
      direction: ltr;
      font-family: 'Material Icons Outlined';
      font-size: 1.5rem;
      float: left;
      margin-right: .25rem;
    }
  }

  .mat-simple-snackbar-action {
    margin: 0;

    .mat-button {
      padding: 0 .25rem;
    }
  }
}

@media (min-width: 600px) {
  .mat-snack-bar-container.snack-bar {
    margin: 20px 0;
    max-width: 65vw;
  }
}

@media (min-width: 960px) {
  .mat-snack-bar-container.snack-bar {
    margin: 24px 0;
    max-width: 40vw;
  }
}
