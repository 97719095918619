/* You can add global styles to this file, and also import other style files */

:root {
  /** Font sizes **/
  --font-size-h1: 2rem;
  --font-size-h2: 1.5rem;
  --font-size-h3: 1.25rem;
  --font-size-h4: 1.125rem;
  --font-size-h5: 1rem;
  --font-size-h6: 0.875rem;
  --font-size-small: 0.75rem;
  --line-height-h1: 2.5rem;
  --line-height-h2: 2rem;
  --line-height-h3: 1.75rem;
  --line-height-h4: 1.5rem;
  --line-height-h5: 1.25rem;
  --line-height-h6: 1rem;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Proxima Nova', sans-serif;
}

body * {
  box-sizing: border-box;
}

table {
  width: 100%;
}

.row {
  display: flex;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}

/** MatDialog **/
.cdk-overlay-pane .mat-dialog-container {
  padding: 0;
  border-radius: 16px;
  overflow: hidden;
}

/** Scrollbar **/
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  border-radius: 6px;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: var(--black-alpha-35);
}
